.c-interactive-tool {
  width: 100%;
  position: relative;

  img {
    width: 100%;
    height: auto;
  }
}

.c-interactive-tool--panel {
  display: none;
  max-width: 1000px;
  padding: 2rem 2rem 0 2rem;
}

.c-interactive-tool--section {
  margin-bottom: 2rem;
  clear: both;
  overflow: hidden;
}

.c-interactive-tool--section img {
  float: left;
  width: 25%;
}

.c-interactive-tool--section .content {
  float: right;
  width: 70%;
}

.c-interactive-tool--section h4 {
  margin-bottom: 1rem;
}

.c-interactive-tool--section .content p {
  font-size: 0.875rem;
}

.c-interactive-tool--section .content .c-btn {
  height: 2.5rem;
  line-height: 2.5rem;
}

.c-interactive-tool button {
  opacity: 0;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  position: absolute;
  border-radius: 4px;
  background: rgba(51, 232, 60, 0.34);

  &:hover {
    opacity: 1;
    border-radius: 4px;
    background: rgba(51, 232, 60, 0.34);
  }
}

// Items

.c-interactive-tool .LightCONEX_dual {
  width: 14%;
  height: 15%;
  left: 25%;
  top: 2%;
}

.c-interactive-tool .LightCONEX_single {
  width: 10%;
  height: 19%;
  left: 84%;
  top: 2%;
}

.c-interactive-tool .SpaceABLE28 {
  width: 9%;
  height: 18%;
  left: 11%;
  top: 21%;
}

.c-interactive-tool .LightVISIONVMMidboard {
  width: 11%;
  height: 25%;
  left: 24%;
  top: 23%;
}

.c-interactive-tool .LightABLELL {
  width: 9%;
  height: 17%;
  left: 54%;
  top: 23%;
}

.c-interactive-tool .LightABLELMMEG_Array {
  width: 10%;
  height: 17%;
  left: 80%;
  top: 30%;
}

.c-interactive-tool .LightABLELMSMT {
  width: 10%;
  height: 17%;
  left: 80%;
  top: 51%;
}

.c-interactive-tool .LightABLELL12TRX {
  width: 9%;
  height: 21%;
  left: 61%;
  top: 56%;
}

.c-interactive-tool .LightVISIONboardedge {
  width: 12%;
  height: 27%;
  left: 38%;
  top: 73%;
}

.ui-tooltip {
  display: none !important;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 22px;
  position: absolute;
  z-index: 9;
  max-width: 300px;
  border-width: 2px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);

  @include bp-medium {
    display: block !important;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #ffffff transparent transparent transparent;
    z-index: 10;
  }
}

// Logs (generated at bottom of page)

.ui-helper-hidden-accessible {
  @include hidden-visually;
}
